import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeM from '../views/gb/mobile.vue'
import Home from '../views/home.vue'
// import UAParser from 'ua-parser-js';
// const uaParser = new UAParser();
// const deviceInfo = uaParser.getResult();

// const isMobileDevice = deviceInfo.device.type === 'mobile';
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'en-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Latest Version Download 2025 Anti-Ban Enhannced`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `What is GBWhatsApp and what's new in GBWhatsApp latest version after ban wave? Visit our site to learn more about this WhatsApp Mod and download the APK safely.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/'
				}
			]
		},
		component: Home,
	},
	{
		path: '/new',
		name: 'Homenew',
		meta: {
			appname: 'GBWhatsApp',
		},
		component: Home,
	},
	{
		path: '/gbwhatsapp-downloadpage',
		name: 'download',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp Latest Version Download Page`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Download GB WhatsApp APK for Android on this safe website. No ads and no hidden fees. Follow the guide to download it now.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/gbwhatsapp-downloadpage/'
				}
			]
		},
		component: () => import('@/views/download.vue'),
	},
	{
		path: '/blogs',
		name: 'blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: ` GBWhatsApp | Why do people prefer GB WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `If you are concerned about GBWhatsapp's safety or GBWhatsapp's ban issue or any other questions about GBWhatsapp, please read this.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/blogs/'
				}
			]
		},
		component: () => import('@/views/blogs.vue'),
	},
	{
		path: '/gbwhatsapp-backup-to-google-drive',
		name: 'blog1',
		meta: {
			appname: 'GBWhatsApp',
			title: `[Updated] How to Backup GB WhatsApp Chat to Google Drive/PC 2025`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Are you looking for backup GB WhatsApp chat or other media files? Google Drive or PC is a good place to save the data since GB WhatsApp is a mod app.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/gbwhatsapp-backup-to-google-drive/'
				}
			]
		},
		component: () => import('@/views/blog1.vue'),
	},
	{
		path: '/id/yowhatsapp-download-terbaru',
		name: 'blog2',
		meta: {
			appname: 'GBWhatsApp',
			title: `Unduh YoWhatsApp Terbaru 2025: Fitur, Instalasi, dan Ulasan`,
			keywords: 'yowhatsapp, yo whatsapp, yowhatsapp terbaru, yo whatsapp terbaru, download yowhatsapp terbaru 2022',
			description: `Dapatkan YoWhatsApp terbaru 2025 dengan fitur canggih dan privasi lebih baik. Pelajari cara instalasi, bandingkan dengan GBWhatsApp, dan baca ulasan pengguna. Unduh sekarang!`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/id/yowhatsapp-download-terbaru/'
				}
			]
		},
		component: () => import('@/views/blog2.vue'),
	},
	{
		path: '/how-to-download-whatsapp-status-video',
		name: 'blog3',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Download WhatsApp Status Video on Android & iPhone`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Want to save WhatsApp status videos? Learn how to download WhatsApp status videos on Android and iPhone, and by using GB WhatsApp.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/how-to-download-whatsapp-status-video/'
				}
			]
		},
		component: () => import('@/views/blog3.vue'),
	},
	{
		path: '/privacy',
		name: 'privacy',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/privacy/'
				}
			]
		},
		component: () => import('@/views/privacy.vue'),
	},
	{
		path: '/about-us',
		name: 'about',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/about-us/'
				}
			]
		},
		component: () => import('@/views/about.vue'),
	},
	{
		path: '/ar',
		name: 'ar-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK تنزيل الإصدار V18.30 2024 لشهر يونيو`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `واتساب الذهبي هو تطبيق رائع لأي شخص يريد تجربة واتساب أكثر تخصيصًا وغنية بالميزات. قم بتنزيل واتساب الذهبي للحصول على الإصدار الثابت.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/ar/'
				}
			]
		},
		component: () => import('@/views/ar.vue'),
	},
	{
		path: '/id',
		name: 'id-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK Unduh Versi V18.30 Juni 2024`,
			description: `GBWhatsApp Pro adalah aplikasi yang hebat untuk siapa saja yang menginginkan pengalaman WhatsApp yang lebih personal dan kaya fitur. Unduh GBWhatsApp untuk versi yang sudah diperbaiki.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/id/'
				}
			]
		},
		component: () => import('@/views/id.vue'),
	},
	{
		path: '/po',
		name: 'po-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK Download Versão V18.30 Junho 2024`,
			description: `GBWhatsApp Pro é um ótimo aplicativo para quem deseja uma experiência do WhatsApp mais personalizada e rica em recursos. Baixe o GBWhatsApp para a versão corrigida.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/po/'
				}
			]
		},
		component: () => import('@/views/po.vue'),
	},
	{
		path: '/tr',
		name: 'tr-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK İndir V18.30 2024 Haziran Sürümü`,
			description: `GBWhatsApp Pro, daha kişiselleştirilmiş ve özellik açısından zengin bir WhatsApp deneyimi isteyen herkes için harika bir uygulamadır. Düzeltme sürümü için GBWhatsApp'ı indirin.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/tr/'
				}
			]
		},
		component: () => import('@/views/tr.vue'),
	},
	{
		path: '/in',
		name: 'in-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp|GB WhatsApp Pro APK डाउनलोड V18.30 2024 जून संस्करण`,
			description: `GBWhatsApp Pro उन सभी के लिए एक शानदार ऐप है जो अधिक व्यक्तिगत और सुविधाओं से भरपूर WhatsApp अनुभव चाहते हैं। फिक्स्ड संस्करण के लिए GBWhatsApp डाउनलोड करें।`,
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/in/'
				}
			]
		},
		component: () => import('@/views/in.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://gbapks.com.ru/transfer/'
				}
			]
		},
		component: () => import('@/views/transfer.vue'),
	},
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue'),
  }
]

// export const constRoutesM = [
// 	{
// 		path: '/',
// 		name: 'Home',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: true,
// 			showTelegram: true,
// 		},
// 		// component: HomeM,
// 	},
// 	{
// 		path: '/download',
// 		name: 'download',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: false,
// 		},
// 		component: () => import('@/components/download.vue'),
// 	},
// ]

let routes = []
// if (isMobileDevice) {
// 	routes = constRoutesM
// }
// if (!isMobileDevice) {
// 	routes = constRoutesPc
// }

routes = constRoutesPc
export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
